import React, { useState, useEffect, Suspense } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import { Form, Input, Table, Spin, Drawer } from 'antd'
import SingleAccountsAction from 'redux/singleaccounts/actions'
import { getFormattedDate } from 'utils/parser'
import CreateInvoiceItemComp from './CreateInvoiceItem'

const { Search } = Input

const mapStateToProps = ({ singleaccounts, settings }) => ({
  singleaccounts,
  settings,
})

const PaymentHistory = ({ singleaccounts, settings }) => {
  const dispatch = useDispatch()
  const { formatMessage, formatNumber } = useIntl()
  const [page, setPage] = useState(1)

  const path = window.location.pathname
  const idval = path.replace('/manage/accounts/', '')

  useEffect(() => {
    if (idval !== null)
      dispatch({
        type: SingleAccountsAction.LOAD_PROFORMA,
        payload: idval,
      }) // eslint-disable-next-line
  }, [dispatch])

  const showpdfdocs = value => {
    window.open(`https://api.bennyenterprises.net/${value}`)
  }

  const filterProformaInvoiceData = singleaccounts?.proformaList

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.businessName' }),
      dataIndex: ['business_name'],
      key: 'business_name',
    },
    {
      title: formatMessage({ id: 'text.proforma_number' }),
      dataIndex: 'proforma_number',
      key: 'proforma_number',
      render: (text, record) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div role="button" className="font-weight-bold" onClick={() => showproforma(record)}>
          <span style={{ color: 'red !important' }}>{`ACE-${record?.proforma_number}`}</span>
        </div>
      ),
    },
    {
      title: formatMessage({ id: 'text.invoiceAmount' }),
      dataIndex: 'total',
      key: 'total',
      render: (record, text) => {
        const totalval = text.total
        const discountval = text.discount
        const totalres = totalval - discountval
        const cgstval = text.cgst
        const cgstres = (totalres * cgstval) / 100
        const sgstval = text.sgst
        const sgstres = (totalres * sgstval) / 100
        const igstval = text.igst
        const igstres = (totalres * igstval) / 100
        const totalResult = Number(totalres) + Number(cgstres) + Number(sgstres) + Number(igstres)

        return (
          <span>
            {formatNumber(totalResult, {
              style: 'currency',
              currency: 'INR',
            })}
          </span>
        )
      },
    },
    {
      title: formatMessage({ id: 'text.invoiceDate' }),
      dataIndex: 'created_date',
      key: 'created_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.createdby' }),
      dataIndex: 'createdby',
      key: 'createdby',
    },
    {
      title: formatMessage({ id: 'text.Action' }),
      key: 'url',
      render: text => (
        <>
          <button
            type="button"
            className="btn btn-light"
            title="Delete Lead"
            onClick={() => showpdfdocs(text.url)}
          >
            <i className="fe fe-download mr-1" aria-hidden />
          </button>
        </>
      ),
    },
  ]

  const onSearch = value => {
    dispatch({
      type: SingleAccountsAction.SET_STATE,
      payload: { searchinvoice: value },
    })
  }

  const showproforma = value => {
    dispatch({
      type: SingleAccountsAction.VIEW_PO,
      payload: {
        invoice_id: value?.id,
      },
    })
  }

  const CreateItem = () => {
    dispatch({
      type: SingleAccountsAction.ADD_ITEM,
    })
  }
  const hideitem = () => {
    dispatch({
      type: SingleAccountsAction.HIDE_ITEM,
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.proformainvoicelist' }),
  }

  return (
    <div style={{ backgroundColor: '#FFFFFF', padding: '10px', borderRadius: 'calc(7px - 1px)' }}>
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.placeholder.search.invoice' })}
              size="large"
              onSearch={onSearch}
              defaultValue={singleaccounts?.searchinvoice}
              allowClear
            />
          </Form.Item>
        </div>
        <div className="col-md-8 text-right">
          <button
            type="button"
            className="btn theme-btn-style"
            onClick={CreateItem}
            style={{ float: 'right', marginTop: '2.5px' }}
          >
            <i className="fe fe-plus mr-1" aria-hidden="true" />
            {formatMessage({ id: 'action.createInvoice' })}
          </button>
        </div>
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {filterProformaInvoiceData?.length >= 0 ? (
            <Table
              dataSource={filterProformaInvoiceData}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>
      </div>
      <Drawer
        title={formatMessage({ id: 'text.createInvoice' })}
        placement="right"
        destroyOnClose
        visible={singleaccounts.displayCreateInvoiceDrawer}
        onClose={hideitem}
        width={settings.isMobileView ? '100%' : '60%'}
      >
        <CreateInvoiceItemComp />
      </Drawer>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(PaymentHistory))
