import React, { useState, Suspense } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Input, Table, Form, Spin, Tag, Space, Drawer } from 'antd'
import moment from 'moment'
import { createStructuredSelector } from 'reselect'
import { selectSingleAccounts, selectQuotationInfo } from 'redux/singleaccounts/selectors'
import { SelectQuotation } from 'redux/quotation/selectors'
import { selectSettings } from 'redux/settings/selectors'
import { selectUser } from 'redux/user/selectors'
import SingleAccountsAction from 'redux/singleaccounts/actions'
import AddUploadDesign from '../AddUploadDesign'
import ViewImagePopup from '../ViewImagePopup'
import RemoveModal from '../RemoveModal'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    singleAccount: selectSingleAccounts,
    selectedSingleQuotation: selectQuotationInfo,
    settings: selectSettings,
    quotation: SelectQuotation,
    user: selectUser,
  })

const ViewSlider = ({ singleAccount, settings, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const onSearch = value => {
    dispatch({
      type: SingleAccountsAction.SET_STATE,
      payload: { searchDesign: value },
    })
  }

  const showCreate = () => {
    dispatch({
      type: SingleAccountsAction.SHOW_CREATE,
    })
  }

  const hideCreate = () => {
    dispatch({
      type: SingleAccountsAction.HIDE_CREATE,
    })
  }

  const showImage = values => {
    dispatch({
      type: SingleAccountsAction.SHOW_POPUP,
      payload: { ...values },
    })
  }
  const showpdf = values => {
    window.open(`https://api.bennyenterprises.net/upload_design/${values}`)
  }

  

  const hideImagePopup = () => {
    dispatch({
      type: SingleAccountsAction.HIDE_POPUP,
    })
  }

  const ShowDeleteImage = values => {
    dispatch({
      type: SingleAccountsAction.DELETE_SHOW_IMAGE,
      payload: { ...values },
    })
  }

  const deleteImage = () => {
    dispatch({
      type: SingleAccountsAction.DELETEIMAGE,
      payload: {
        imageId: singleAccount?.selectedData?.id,
        filename: singleAccount?.selectedData?.filename,
      },
    })
  }

  const hidedeleteimage = () => {
    dispatch({
      type: SingleAccountsAction.DELETE_HIDE_IMAGE,
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.NoDesignListData' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.quotation_id' }),
      dataIndex: ['quotation_id'],
      key: 'quotation_id',
    },
    {
      title: formatMessage({ id: 'text.filename' }),
      dataIndex: 'filename',
      key: 'filename',
      render: (record, text) => (
        <>
          {record}{' '}
          <Tag
            color={(() => {
              switch (text?.status) {
                case '0':
                  return 'red'
                default:
                  return 'green'
              }
            })()}
            style={{ textTransform: 'capitalize' }}
          >
            {text.status === '1' && <>Active</>}
            {text.status === '0' && <>Inactive</>}
          </Tag>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.created_date' }),
      dataIndex: ['created_date'],
      key: 'created_date',
      render: text => moment(text).format('MMM D, YYYY HH:mm:ss A'),
    },
    {
      title: formatMessage({ id: 'text.action' }),
      key: 'action',
      render: record => (
        <>
          <Space size="middle">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => showpdf(record.filename)}
              hidden={record.filename.split('.').pop()!=='pdf'}
            >
              {formatMessage({ id: 'action.ViewPDF' })}
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => showImage(record)}
              hidden={record.filename.split('.').pop()==='pdf'}
            >
              {formatMessage({ id: 'action.View' })}
            </button>
            <button type="button" className="btn btn-light" onClick={() => ShowDeleteImage(record)}>
              {formatMessage({ id: 'action.delete' })}
            </button>
          </Space>
        </>
      ),
    },
  ]

  console.log('inside view', singleAccount)

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.placeholder.searchDesign' })}
              size="large"
              onChange={e => onSearch(e.target.value)}
              allowClear
            />
          </Form.Item>
        </div>

        <div className="col-md-8 text-right">
          <button
            type="button"
            className="btn theme-btn-style"
            onClick={showCreate}
            style={{ float: 'right', marginTop: '2.5px' }}
          >
            <i className="fe fe-plus mr-1" aria-hidden="true" />
            {formatMessage({ id: 'text.uploadDesign' })}
          </button>
        </div>
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {singleAccount?.designInfo?.length >= 0 ? (
            <Table
              dataSource={singleAccount?.designInfo}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>
      </div>
      <Drawer
        title={formatMessage({ id: 'text.uploadDesign' })}
        placement="right"
        destroyOnClose
        visible={singleAccount?.displayUploadDesign}
        onClose={hideCreate}
        width={settings?.isMobileView ? '100%' : '44%'}
      >
        <AddUploadDesign loading={singleAccount?.creating} />
      </Drawer>
      <ViewImagePopup
        visible={singleAccount?.displayUploadedImage}
        title={`Design Image - ${singleAccount?.selectedData?.filename}`}
        description={singleAccount?.selectedData?.filename}
        onCancel={hideImagePopup}
      />
      <RemoveModal
        visible={singleAccount?.displayDeleteImagePopup}
        title={`Design Image - ${singleAccount?.selectedData?.filename}`}
        description="Are you sure, you want to delete this image?."
        onCancel={hidedeleteimage}
        onSubmit={deleteImage}
      />
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ViewSlider))
