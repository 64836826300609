import React from 'react'
import { injectIntl } from 'react-intl'
import { Modal } from 'antd'

const RemoveModal = ({ visible, title, description, onCancel }) => {
  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
      <div className="font-size-16">
        <img
          src={`https://api.bennyenterprises.net/upload_design/${description}`}
          alt="avatar"
          style={{ width: '100%' }}
        />
      </div>
    </Modal>
  )
}

export default injectIntl(RemoveModal)
